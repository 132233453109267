import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { notification, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import Card from "./Card";
import Bank from "./Bank";

const openNotification = (type, title, description) => {
    notification[type]({
        message: `${title}`,
        description: `${description}`,
    });
};

function PaymentAuthorizations({ user }) {
    const history = useHistory();

    useEffect(() => {
        if (
            !user.data.hasOwnProperty("user_profile") ||
            user.data.user_profile == null ||
            !user.data.user_profile.bvn
        ) {
            openNotification("error", "Incomplete KYC", "Please, complete KYC to continue");

            const param = new URLSearchParams({
                redirect: "apply",
            });

            setTimeout(() => {
                history.push(`/dashboard/settings/?${param.toString()}`);
            }, 1000);
        }
    }, []);

    const [paymentOption, setPaymentOption] = useState("bank_direct_debit");

    return (
        <Row>
            <Col span={12} className="z-res-12">
                <div className="z-height-100_ z-dash-p z-apply-shadow z-dash-apply">
                    <h1 className="z-dashboard-label">Authorizations</h1>
                    <Row>
                        <Col>
                            <div className="z-flex-space z-guarantor-padding2 z-mb-3">
                                <div
                                    className={
                                        paymentOption === "bank_direct_debit"
                                            ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                            : "z-cursor-poiner z-width-100 z-flex-row"
                                    }
                                    onClick={() =>
                                        setPaymentOption("bank_direct_debit")
                                    }
                                >
                                    <p className="z-guarantor-tab z-ml-8 z-notification-active">
                                        Bank Accounts
                                    </p>
                                </div>
                                <div
                                    className={
                                        paymentOption === "card"
                                            ? "z-width-100 z-flex-row z-guarantor-active z-cursor-poiner"
                                            : "z-cursor-poiner z-width-100 z-flex-row"
                                    }
                                    onClick={() => setPaymentOption("card")}
                                >
                                    <p className="z-guarantor-tab z-ml-8 z-notification-active">
                                        Debit Cards
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {paymentOption === "bank_direct_debit" ? (
                        <Bank hideProceedBtn={true} />
                    ) : (
                        <Card hideProceedBtn={true} />
                    )}
                </div>
            </Col>
        </Row>
    );
}

/**
 * function to map the state to props
 * */
function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(PaymentAuthorizations);
